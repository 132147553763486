import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import FooterArea from "../../../components/FooterArea";
import breadcumb from "../../../images/breadcumb/bg.jpg";
import single from "../../../images/areas-de-atuacao/administrativo770x500.png";
// import "../PaginaAreasDeAtuacao/style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Áreas de atuação", route: "/areas-de-atuacao" }, { name: "Administrativo" }];

const SinglePortfolioPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Administrativo" breadcumbMenu={breadcumbMenu} background={breadcumb} />

      <div className="aboutArea aboutAreaStyleTwo">
        <div className="container">
          <div className="row">
            <div className="order-last col col-lg-6">
              <div className="aboutImageWrap">
                <img src={single} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutContent">
                <span>Atuação</span>
                <h2>Administrativo</h2>
                <p>
                  Elaboração, impugnações e recursos administrativos, análise de contratos administrativos, pareceres, estudos e definições de estratégias de
                  condutas de empresas perante a Administração Pública Direta e Indireta.
                </p>
                <p>
                  Atuação em processos (demandas) de licitação e procedimentos administrativos em processo de estruturação de operações sujeitas a notificação
                  ou aprovação por parte da administração pública.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterArea />
    </Fragment>
  );
};

export default SinglePortfolioPage;
