import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";
import testimonial from "../../images/testimonial/1.png";
import quibom from "../../images/testimonial/quibom1.png";
import brilhante from "../../images/testimonial/brilhante1.png";
import pampa from "../../images/testimonial/pampas1.png";
import nacional from "../../images/testimonial/nacional1.png";
import prima from "../../images/testimonial/prima1.png";
import estrela from "../../images/testimonial/estrela1.png";
import bigbom from "../../images/testimonial/bigbom1.png";
import grell from "../../images/testimonial/grell1.png";
import japao from "../../images/testimonial/japao1.png";
import masterpao from "../../images/testimonial/master1.png";
import medmais from "../../images/testimonial/medmais1.png";

const sliders = [
  {
    images: brilhante,
    title: "Brilhante",
    subTitle: "Arroz",
  },
  {
    images: quibom,
    title: "QuiBom",
    subTitle: "Supermercados",
  },
  {
    images: pampa,
    title: "Pampas",
    subTitle: "Churrascaria",
  },
  {
    images: nacional,
    title: "Nacional",
    subTitle: "Panificadora",
  },
  {
    images: prima,
    title: "Prima",
    subTitle: "Home Center",
  },
  {
    images: estrela,
    title: "Estrela",
    subTitle: "Elétrica",
  },
  {
    images: bigbom,
    title: "BigBom",
    subTitle: "Supermecados",
  },
  {
    images: grell,
    title: "Grell",
    subTitle: "Arq & Decor",
  },
  {
    images: japao,
    title: "Japão",
    subTitle: "da Construção",
  },
  {
    images: masterpao,
    title: "Master Pão",
    subTitle: "Padaria",
  },
  {
    images: medmais,
    title: "Med Mais",
    subTitle: "Serviços Médicos",
  },
];

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 512,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Testimonial = ({ className }) => {
  return (
    <div id="clientes" className={className}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="testimonialImages">
              <img src={testimonial} alt="" />
            </div>
          </div>
          <div className="col-lg-7 offset-lg-1 col-md-6">
            <div className="testimonialContent">
              <span>Experiência comprovada</span>
              <h2>Alguns de nossos clientes</h2>
              <div className="testimonialSlider">
                <div className="slideItem">
                  <p>
                    A Araújo Rabelo é um modelo de trabalho orientado por uma política de valores capaz de alocar e gerir recursos, ações, iniciativas,
                    princípios, valores e estratégias, viabilizando o alcance dos objetivos propostos pela empresa.
                  </p>
                  <Slider {...settings}>
                    {sliders.map((slider, i) => (
                      <div key={i} className="slideItem">
                        <div className="thumbWrap">
                          <div className="thumbImg">
                            <img src={slider.images} alt="" />
                          </div>
                          <div className="imgContent">
                            <h4>{slider.title}</h4>
                            <span>{slider.subTitle}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
