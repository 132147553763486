import React from "react";
import SectionTitle from "../Title";
import { toast } from "react-toastify";
import "./style.scss";

const initialState = {
  news: [],
  isLoading: true,
};

class BlogArea extends React.Component {
  state = initialState;

  componentDidMount() {
    const request = new XMLHttpRequest();
    request.onload = () => {
      const news = JSON.parse(request.responseText);

      this.setState({ isLoading: false, news });
    };

    request.onerror = () => {
      toast.warning("Falha ao obter notícias. Tente novamente mais tarde.", {
        autoClose: 15000,
        pauseOnHover: true,
        closeOnClick: true,
      });

      this.setState({ isLoading: false });
    };

    request.overrideMimeType("application/json");
    request.open("GET", "/news.json", true);
    request.send();
  }

  render() {
    const { news } = this.state;
    const { className, title, subTitle } = this.props;

    return (
      <div id="noticias" className={className}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <SectionTitle title={title} subTitle={subTitle} />
            </div>
            {news.map((item, i) => (
              <div key={i} className="col-lg-4 col-sm-6 col-12">
                <div className="blogWrap">
                  {item.image_src && (
                    <div className="blogImage">
                      <img src={item.image_src} alt={item.image_alt} />
                    </div>
                  )}
                  <div className="blogContent">
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                      <h3>{item.title}</h3>
                    </a>
                    <ul className="blogMeta">
                      <li>{item.creator}</li>
                      <li>{item.published_at_formatted}</li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default BlogArea;
