import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import FooterArea from "../../../components/FooterArea";
import ContactArea from "../../../components/ContactArea";

// images
import breadcumb from "../../../images/breadcumb/bg.jpg";
import team from "../../../images/expert/danilo-600x600.jpeg";

import "./style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Advogados", route: "/advogados" }, { name: "Danilo Andrei" }];
const teamMembarInfo = [
  { level: "Posição: ", text: "Assistente jurídico" },
  { level: "Registro: ", text: "-" },
  { level: "Área de atuação: ", text: "Cível; Trabalhista e Previdenciário; Administrativo; Tributário; Compliance" },
  { level: "Experiência: ", text: "-" },
  { level: "Telefone: ", text: "-" },
  { level: "Email: ", text: "danilo@araujorabelo.adv.br" },
];

const SingleTeamPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Danilo Andrei" breadcumbMenu={breadcumbMenu} background={breadcumb} />
      <div className="singleTeamArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="singleTeamImgWrap">
                    <div className="singleTeamImg">
                      <img src={team} alt="" />
                    </div>
                    <h4>Danilo Andrei</h4>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="singleTeamContent">
                    <h4>Informações importantes</h4>
                    <ul className="teamMembarInfo">
                      {teamMembarInfo.map((teamInfo, i) => (
                        <li key={i}>
                          <span>{teamInfo.level}</span>
                          {teamInfo.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="singleTeamInfo">
                    <h3>Experiência profissional</h3>
                    <p>
                      Graduando do Curso de Direito-UniCeub.
                      <br />
                      Contribui para a rotina do escritório, realizando tarefas administrativas e suporte à equipe em atendimentos à clientes, atualização da
                      base de doutrina e jurisprudência, controle de prazos e diligências.
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactArea className="contactArea" />
      <FooterArea />
    </Fragment>
  );
};
export default SingleTeamPage;
