import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import HeroSlider from "../../../components/HeroSlider";
import Service from "../../../components/Service";
import About from "../../../components/About";
import ServiceArea from "../../../components/ServiceArea";
import Testimonial from "../../../components/Testimonial";
import ContactArea from "../../../components/ContactArea";
import TeamMember from "../../../components/TeamMember";
import BlogArea from "../../../components/BlogArea";
import FooterArea from "../../../components/FooterArea";

import about from "../../../images/about/4.jpg";

const aboutText = [
  {
    text:
      "O escritório Araújo Rabelo atua, no Distrito Federal e Entorno, há mais de treze anos na área jurídica preventiva e contenciosa das empresas, prestando uma assessoria completa, atenta às necessidades efetivas do mercado corporativo, fazendo com que, por meio de uma abordagem interdisciplinar, os ditames regulatórios sejam cumpridos na execução do seu negócio.",
  },
];

const heroSliders = [
  {
    images: "slideWrapperOne",
    title: "Gestão Jurídica De Investimento",
    subTitle: "",
    text: "Imprescindível para sua empresa",
    buttonText: "Contate-nos agora",
    buttonLink: "/contato",
  },
  {
    images: "slideWrapperTwo",
    title: "Aumente Suas Vantagens Competitivas",
    subTitle: "",
    text: "Obtenha nossa Gestão Jurídica",
    buttonText: "Contate-nos agora",
    buttonLink: "/contato",
  },
];

const services = [
  {
    icon: "flaticon-law-2",
    title: "Cível",
    content: "Prevenção de ações judiciais para garantir que os interesses de nossos clientes sejam protegidos no caso de posterior discussão em Juízo.",
    linkTo: "/areas-de-atuacao/civel",
  },
  {
    icon: "flaticon-employee",
    title: "Trabalhista e Previdenciário",
    content: "Opiniões legais e elaboração de documentos, auditoria, gerenciamento de RH, negociações coletivas e outros.",
    linkTo: "/areas-de-atuacao/trabalhista-e-previdenciario",
  },
  {
    icon: "flaticon-cost",
    title: "Tributário",
    content: "Aplicação da legislação competente quanto aos procedimentos fiscais e tributários.",
    linkTo: "/areas-de-atuacao/tributario",
  },
  {
    icon: "flaticon-lawyer",
    title: "Administrativo",
    content: "Questões pertinentes a Administração Pública Direta e Indireta.",
    linkTo: "/areas-de-atuacao/administrativo",
  },
  {
    icon: "flaticon-test",
    title: "Compliance",
    content: "Consultoria a pessoas jurídicas na avaliação e mitigação dos riscos empresariais.",
    linkTo: "/areas-de-atuacao/compliance",
  },
];

export default () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <HeroSlider sliders={heroSliders} className="heroSliderArea" />
      <Service className="serviceArea" />
      <About className="aboutArea" title="Sobre nós" images={about} pragraphs={aboutText} />
      <ServiceArea className="ourServiceArea" title="Como podemos te ajudar" subTitle="Áreas de atuação" services={services} />
      <Testimonial className="testimonialArea" />
      <ContactArea className="contactArea" />
      <TeamMember title="Advogados qualificados" subTitle="Conheça nossos profissionais" className="teamArea" slider={true} />
      <BlogArea className="blogArea" title="Últimas notícias" subTitle="O que está acontecendo" />
      <FooterArea />
    </Fragment>
  );
};
