import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import FooterArea from "../../../components/FooterArea";
import breadcumb from "../../../images/breadcumb/bg.jpg";
import single from "../../../images/areas-de-atuacao/civel770x500.png";
// import "../PaginaAreasDeAtuacao/style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Áreas de atuação", route: "/areas-de-atuacao" }, { name: "Cível" }];

const SinglePortfolioPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Cível" breadcumbMenu={breadcumbMenu} background={breadcumb} />

      <div className="aboutArea aboutAreaStyleTwo">
        <div className="container">
          <div className="row">
            <div className="order-last col col-lg-6">
              <div className="aboutImageWrap">
                <img src={single} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutContent">
                <span>Atuação</span>
                <h2>Cível</h2>
                <p>
                  Prevenção de ações judiciais e composição de interesses por meio de acordo e transações, de modo a garantir que os interesses de nossos
                  clientes sejam protegidos no caso de posterior discussão em Juízo.
                </p>
                <p>
                  Assessoria na análise, elaboração e negociação de contratos, quer em comerciais ou cíveis, buscando sempre um desfecho com a maior segurança
                  jurídica possível, quem em contrato de compra e venda de bens móveis e imóveis, contratos de garantia real (hipotecas, penhores e demais),
                  contrato de distribuição, representação comercial e outros.
                </p>
                <p>
                  Assessorar o cliente em demandas judiciais através de defesas, propositura de demandas relacionadas ao direito do consumidor, direito das
                  obrigações, direito imobiliário, falência e recuperação judicial, direito bancário, propriedade intelectual, indenização por dano material,
                  moral e outros.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterArea />
    </Fragment>
  );
};

export default SinglePortfolioPage;
