import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import FooterArea from "../../../components/FooterArea";
import breadcumb from "../../../images/breadcumb/bg.jpg";
import single from "../../../images/areas-de-atuacao/trabalhista770x500.png";
// import "../PaginaAreasDeAtuacao/style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Áreas de atuação", route: "/areas-de-atuacao" }, { name: "Trabalhista e Previdenciário" }];

const SinglePortfolioPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Trabalhista e Previdenciário" breadcumbMenu={breadcumbMenu} background={breadcumb} />

      <div className="aboutArea aboutAreaStyleTwo">
        <div className="container">
          <div className="row">
            <div className="order-last col col-lg-6">
              <div className="aboutImageWrap">
                <img src={single} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutContent">
                <span>Atuação</span>
                <h2>Trabalhista e Previdenciário</h2>
                <p>
                  Atendimento total e personalizado em matéria preventiva através de ato consultivo em questão de direitos individuais e coletivos, através de
                  opiniões legais e elaboração de documentos, gerenciamento de RH, negociações coletivas e outros.
                </p>
                <p>
                  Elaboração de auditoria trabalhista, avaliando procedimentos para elaborações de estratégias para eliminação e redução de eventuais
                  contingências e apontando as adequações necessárias para o cumprimento das obrigações legais, tanto em questões trabalhistas quanto
                  previdenciárias.
                </p>
                <p>
                  Aconselhamento preventivo e atuação em processos administrativos contenciosos (DRT, MTE, Sindicatos, Associações, Conselhos de Classe e
                  outros).
                </p>
                <p>Atuação em reclamações trabalhistas individuais ou coletivas.</p>
                <p>Análise completa do passivo trabalhista empresarial.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterArea />
    </Fragment>
  );
};

export default SinglePortfolioPage;
