import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import FooterArea from "../../../components/FooterArea";
import ContactArea from "../../../components/ContactArea";

// images
import breadcumb from "../../../images/breadcumb/bg.jpg";
import team from "../../../images/expert/kelen-600x600.jpeg";

import "./style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Advogados", route: "/advogados" }, { name: "Kelen Rabelo" }];
const teamMembarInfo = [
  { level: "Posição: ", text: "Advogada líder" },
  { level: "Registro: ", text: "OAB DF 24227" },
  { level: "Área de atuação: ", text: "Cível; Trabalhista e Previdenciário; Administrativo; Tributário; Compliance" },
  { level: "Experiência: ", text: "14 anos" },
  { level: "Telefone: ", text: "61 99176-1273" },
  { level: "Email: ", text: "kelen@araujorabelo.adv.br" },
];

const SingleTeamPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Kelen Rabelo" breadcumbMenu={breadcumbMenu} background={breadcumb} />
      <div className="singleTeamArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1 col-12">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="singleTeamImgWrap">
                    <div className="singleTeamImg">
                      <img src={team} alt="" />
                    </div>
                    <h4>Kelen Rabelo</h4>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="singleTeamContent">
                    <h4>Informações importantes</h4>
                    <ul className="teamMembarInfo">
                      {teamMembarInfo.map((teamInfo, i) => (
                        <li key={i}>
                          <span>{teamInfo.level}</span>
                          {teamInfo.text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12">
                  <div className="singleTeamInfo">
                    <h3>Experiência profissional</h3>
                    <p>
                      Graduada em Direito pela Universidade Paulista (UNIP), em 2005. Pós-Graduada em Direito Processual Civil pela Fundação Getúlio Vargas
                      (FGV).
                      <br />
                      Experiência de 14 anos de atuação na área do Direito Empresarial, no contencioso e preventivo de empresas de destaque no Distrito Federal
                      e Goiás. Experiência na área do Direito Administrativo, no contencioso de temas ligados à licitações e contratos. Atua nas áreas do
                      Direito do Trabalho e Direito Civil, com grande expertise em Processual Civil.
                    </p>
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactArea className="contactArea" />
      <FooterArea />
    </Fragment>
  );
};
export default SingleTeamPage;
