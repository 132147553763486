import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import TeamMember from "../../../components/TeamMember";
import FooterArea from "../../../components/FooterArea";
import breadcumb from "../../../images/breadcumb/bg.jpg";
import "./style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Advogados" }];

const TeamPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Advogados" breadcumbMenu={breadcumbMenu} background={breadcumb} />
      <TeamMember title="Advogados qualificados" subTitle="Conheça nossos profissionais" className="teamArea teamAreaStyleTwo" />

      <FooterArea />
    </Fragment>
  );
};

export default TeamPage;
