import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo/ar3.png";
import "./style.scss";

const HeaderBottom = props => {
  const [responsive, setResponsive] = useState(false);

  const responsiveHandler = () => {
    setResponsive(!responsive);
  };

  return (
    <div className={props.className}>
      <div className="container">
        <div className="headerBottomMainWrapper">
          <div className="row">
            <div className="col-lg-3 col-md-10 col-sm-6 col-8 zommedVertCentered">
              <div className="logo">
                <NavLink to="/">
                  <img src={logo} alt="" />
                </NavLink>
              </div>
            </div>
            <div className={responsive ? "col-lg-8 responsiveWrapper active" : "col-lg-8 responsiveWrapper"}>
              <ul className="mainMenuWrap">
                <li>
                  <NavLink exact to="/sobre-nos">
                    Sobre
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/areas-de-atuacao">
                    Atuação
                  </NavLink>
                  <ul className="subMenu">
                    <li>
                      <NavLink exact to="/areas-de-atuacao/civel">
                        Cível
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/areas-de-atuacao/trabalhista-e-previdenciario" style={{ textTransform: "none" }}>
                        Trabalhista e Previdenciário
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/areas-de-atuacao/tributario">
                        Tributário
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/areas-de-atuacao/administrativo">
                        Administrativo
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/areas-de-atuacao/compliance">
                        Compliance
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink exact to="/advogados">
                    Advogados
                  </NavLink>
                  <ul className="subMenu">
                    <li>
                      <NavLink exact to="/advogados/kelen-rabelo">
                        Kelen Rabelo
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/advogados/marcus-pereira">
                        Marcus Pereira
                      </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/advogados/danilo-andrei">
                        Danilo Andrei
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <NavLink exact to="/contato">
                    Contato
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-1 col-md-2 col-sm-6 col-4">
              <div className="searchMenuWrapper">
                <div onClick={responsiveHandler} className="responsiveTrigger">
                  <span className="first"></span>
                  <span className="second"></span>
                  <span className="third"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBottom;
