import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";

import "../src/css/flaticon.css";
import "../src/css/newicons/flaticon.css";
import "../src/css/odometer-theme-default.css";
import "../src/css/animate.css";

import "../src/css/kyrial-sans-pro.css";
import "../src/css/brandon-grotesque.css";

import App from "../src/containers/app";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";

const app = <App />;

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
