import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import FooterArea from "../../../components/FooterArea";
import breadcumb from "../../../images/breadcumb/bg.jpg";
import single from "../../../images/areas-de-atuacao/compliance770x500.png";
// import "../PaginaAreasDeAtuacao/style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Áreas de atuação", route: "/areas-de-atuacao" }, { name: "Compliance" }];

const SinglePortfolioPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Compliance" breadcumbMenu={breadcumbMenu} background={breadcumb} />

      <div className="aboutArea aboutAreaStyleTwo">
        <div className="container">
          <div className="row">
            <div className="order-last col col-lg-6">
              <div className="aboutImageWrap">
                <img src={single} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutContent">
                <span>Atuação</span>
                <h2>Compliance</h2>
                <p>
                  Consultoria a pessoas jurídicas na avaliação e mitigação dos riscos empresariais, referentes às legislações vigentes, criando para o cliente
                  um Programa de Compliance e/ou auxiliando no Programa já vigente em sua organização, com o foco na melhor cultura empresarial,
                  possibilitando a verificação de incongruências que não se encaixam no Programa definido, auxiliando na mitigação das desconformidades, bem
                  como no crescimento interno e externo da organização.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterArea />
    </Fragment>
  );
};

export default SinglePortfolioPage;
