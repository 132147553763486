import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logo/Logo Vertical A_R Branca.png";
import "./style.scss";

const footerLinks = [
  {
    title: "Links rápidos",
    menus: [
      { name: "Home", linkTo: "/" },
      { name: "Sobre", linkTo: "/sobre-nos" },
      { name: "Atuação", linkTo: "/areas-de-atuacao" },
      // { name: "Notícias", linkTo: "/#noticias" },
      { name: "Advogados", linkTo: "/advogados" },
      { name: "Contato", linkTo: "/contato" },
    ],
  },
  {
    title: "Atuação",
    menus: [
      { name: "Cível", linkTo: "/areas-de-atuacao/civel" },
      { name: "Trabalhista e Previdenciário", linkTo: "/areas-de-atuacao/trabalhista-e-previdenciario" },
      { name: "Tributário", linkTo: "/areas-de-atuacao/tributario" },
      { name: "Administrativo", linkTo: "/areas-de-atuacao/administrativo" },
      { name: "Compliance", linkTo: "/areas-de-atuacao/compliance" },
    ],
  },
  {
    title: "Contato",
    menus: [{ name: "QS 02, Conj. 6, Lote 22" }, { name: "Riacho Fundo I, Brasília-DF" }, { name: "(61) 3042-0123" }, { name: "kelen@araujorabelo.adv.br" }],
  },
];

const FooterArea = () => {
  return (
    <footer className="footerArea">
      <div className="footerTopArea">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footerLogo">
                <Link to="/">
                  <img src={logo} alt="" />
                </Link>
              </div>
            </div>
            {footerLinks.map((menu, i) => (
              <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                <div className="footerWrap">
                  <h3>{menu.title}</h3>
                  <ul>
                    {menu.menus.map((item, i) => (
                      <li key={i}>{item.linkTo ? <Link to={item.linkTo}>{item.name}</Link> : `${item.name}`}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footerBottomArea">
        <div className="container">
          <div className="footerBottomContent">
            <div className="row">
              <div className="col-md-8 col-sm-10 col-12">
                <span>© 2020 Araújo Rabelo Advocacia Corporativa. Todos direitos reservados.</span>
              </div>
              <div className="col-md-4 col-sm-2 col-12">
                <ul className="socialListFooter">
                  <li>
                    <a href="https://instagram.com/araujorabeloadv" target="_blank" rel="noopener noreferrer">
                      <i className="fa fa-instagram"></i> @araujorabeloadv
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default FooterArea;
