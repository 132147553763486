import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import Service from "../../../components/Service";
import ServiceArea from "../../../components/ServiceArea";
import FooterArea from "../../../components/FooterArea";

import breadcumb from "../../../images/breadcumb/bg.jpg";

const services = [
  {
    icon: "flaticon-law-2",
    title: "Cível",
    content: "Prevenção de ações judiciais para garantir que os interesses de nossos clientes sejam protegidos no caso de posterior discussão em Juízo.",
    linkTo: "/areas-de-atuacao/civel",
  },
  {
    icon: "flaticon-employee",
    title: "Trabalhista e Previdenciário",
    content: "Opiniões legais e elaboração de documentos, auditoria, gerenciamento de RH, negociações coletivas e outros.",
    linkTo: "/areas-de-atuacao/trabalhista-e-previdenciario",
  },
  {
    icon: "flaticon-cost",
    title: "Tributário",
    content: "Aplicação da legislação competente quanto aos procedimentos fiscais e tributários.",
    linkTo: "/areas-de-atuacao/tributario",
  },
  {
    icon: "flaticon-lawyer",
    title: "Administrativo",
    content: "Questões pertinentes a Administração Pública Direta e Indireta.",
    linkTo: "/areas-de-atuacao/administrativo",
  },
  {
    icon: "flaticon-test",
    title: "Compliance",
    content: "Consultoria a pessoas jurídicas na avaliação e mitigação dos riscos empresariais.",
    linkTo: "/areas-de-atuacao/compliance",
  },
];

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Áreas de atuação" }];

const PracticePage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Áreas de atuação" breadcumbMenu={breadcumbMenu} background={breadcumb} />
      <Service className="serviceArea mt-0" />
      <ServiceArea className="ourServiceArea ourServiceAreaStyleThree bgFFF" title="Como podemos te ajudar" subTitle="Áreas de atuação" services={services} />
      <FooterArea />
    </Fragment>
  );
};

export default PracticePage;
