import React, { Fragment } from 'react'
import HeaderBottom from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
// images
import breadcumb from '../../../images/breadcumb/bg.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contato' },
]

const ContactPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Contato"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />

            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contactUsInfo">
                                <h3>Nosso contato</h3>
                                <p>Utilize o formulário para tirar dúvidas ou agendar uma consultoria. Se preferir, visite-nos de segunda à sexta, das 9h às 18h.
                                </p>
                                <h4>Endereço</h4>
                                <span>QS 02, Conj. 6, Lote 22, Riacho Fundo I, Brasília-DF.<br/>
                                71820-206</span>
                                <h4>Telefone</h4>
                                <span>(61) 3042-0123</span>
                                <span></span>
                                <h4>Email</h4>
                                <span>kelen@araujorabelo.adv.br</span>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contactUSForm">
                                <h3>Preencha o formulário de contato</h3>
                                <Form />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <FooterArea />
        </Fragment>
    )
}
export default ContactPage
