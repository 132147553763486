import React from "react";
import "./style.scss";

const services = [
  { icon: "fi flaticon-group", subtitle: "Agende sua", title: "Consultoria", href: "/#podemos-te-ajudar" },
  { icon: "fi flaticon-law-1", subtitle: "Leia as últimas", title: "Notícias", href: "/#noticias" },
  { icon: "fi flaticon-handshake", subtitle: "Conheça alguns", title: "Clientes", href: "/#clientes" },
];
const Service = props => {
  const handleClick = href => event => {
    window.location.href = href;
  };

  return (
    <div className={props.className}>
      <div className="container">
        <div className="row">
          {services.map((service, i) => (
            <div key={i} className="col-lg-4 col-md-6">
              <div className="serviceItem" onClick={handleClick(service.href)}>
                <div className="serviceIcon">
                  <i className={service.icon}></i>
                </div>
                <div className="serviceText">
                  <span>{service.subtitle}</span>
                  <h3>{service.title}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Service;
