import React, { Component } from "react";
import { BrowserRouter, Switch, Route, withRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import PaginaInicial from "../paginas/PaginaInicial";
import PaginaSobre from "../paginas/PaginaSobre";

import PaginaAreasDeAtuacao from "../paginas/PaginaAreasDeAtuacao";
import PaginaAreaDeAtuacaoCivel from "../paginas/PaginaAreaDeAtuacaoCivel";
import PaginaAreaDeAtuacaoTrabalhistaPrevidenciario from "../paginas/PaginaAreaDeAtuacaoTrabalhistaPrevidenciario";
import PaginaAreaDeAtuacaoTributario from "../paginas/PaginaAreaDeAtuacaoTributario";
import PaginaAreaDeAtuacaoAdministrativo from "../paginas/PaginaAreaDeAtuacaoAdministrativo";
import PaginaAreaDeAtuacaoCompliance from "../paginas/PaginaAreaDeAtuacaoCompliance";

import PaginaAdvogados from "../paginas/PaginaAdvogados";
import PaginaKelenRabelo from "../paginas/PaginaKelenRabelo";
import PaginaMarcusPereira from "../paginas/PaginaMarcusPereira";
import PaginaDaniloAndrei from "../paginas/PaginaDaniloAndrei";

import PaginaContato from "../paginas/PaginaContato";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const rotasQueNaoDevemScrollarParaCima = ["#podemos-te-ajudar", "#noticias", "#clientes"];

    if (-1 !== rotasQueNaoDevemScrollarParaCima.indexOf(this.props.location.hash)) {
      return;
    }

    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

class App extends Component {
  render() {
    const ScrollToTopRouted = withRouter(ScrollToTop);

    return (
      <BrowserRouter>
        <ScrollToTopRouted>
          <ToastContainer autoClose={2500} position="top-center" />
          <Switch>
            <Route exact path="/" component={PaginaInicial} />
            <Route exact path="/sobre-nos" component={PaginaSobre} />
            <Route exact path="/areas-de-atuacao" component={PaginaAreasDeAtuacao} />
            <Route exact path="/areas-de-atuacao/civel" component={PaginaAreaDeAtuacaoCivel} />
            <Route exact path="/areas-de-atuacao/trabalhista-e-previdenciario" component={PaginaAreaDeAtuacaoTrabalhistaPrevidenciario} />
            <Route exact path="/areas-de-atuacao/tributario" component={PaginaAreaDeAtuacaoTributario} />
            <Route exact path="/areas-de-atuacao/administrativo" component={PaginaAreaDeAtuacaoAdministrativo} />
            <Route exact path="/areas-de-atuacao/compliance" component={PaginaAreaDeAtuacaoCompliance} />
            <Route exact path="/advogados" component={PaginaAdvogados} />
            <Route exact path="/advogados/kelen-rabelo" component={PaginaKelenRabelo} />
            <Route exact path="/advogados/marcus-pereira" component={PaginaMarcusPereira} />
            <Route exact path="/advogados/danilo-andrei" component={PaginaDaniloAndrei} />
            <Route exact path="/contato" component={PaginaContato} />
          </Switch>
        </ScrollToTopRouted>
      </BrowserRouter>
    );
  }
}

export default App;
