import React, { Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SectionTitle from "../Title";
import "./style.scss";

// images
import kelenImg from "../../images/expert/kelen.jpeg";
import marcusImg from "../../images/expert/marcus.jpg";
import daniloImg from "../../images/expert/danilo.jpeg";

const teams = [
  {
    name: "Kelen Rabelo",
    level: "Advogada & Fundadora",
    image: kelenImg,
    url: "/advogados/kelen-rabelo",
    socialMedia: [
      {
        icon: "instagram",
        url: "https://www.instagram.com/kelencarabelo/",
      },
    ],
  },
  {
    name: "Marcus Pereira",
    level: "Advogado",
    image: marcusImg,
    url: "/advogados/marcus-pereira",
    socialMedia: [
      {
        icon: "instagram",
        url: "https://www.instagram.com/marcuspereiraadv/",
      },
    ],
  },
  {
    name: "Danilo Andrei",
    level: "Assistente jurídico",
    image: daniloImg,
    url: "/advogados/danilo-andrei",
    socialMedia: [
      {
        icon: "instagram",
        url: "https://www.instagram.com/danilo_andr7/",
      },
    ],
  },
];

const settings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 512,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const TeamWrapper = ({ team, key }) => {
  return (
    <div key={key} className="teamWrapper">
      <div className="teamImage">
        <Link to={team.url}>
          <img src={team.image} alt={team.name} />
        </Link>
      </div>
      <div className="teamContent">
        <Link to={team.url}>
          <h3>{team.name}</h3>
        </Link>
        <span>{team.level}</span>
        <ul>
          {team.socialMedia.map(social => (
            <li key={social}>
              <a href={social.url} target="_blank" rel="noopener noreferrer">
                <i className={`fa fa-${social.icon}`} aria-hidden="true"></i>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const TeamMember = ({ className, title, subTitle, slider, noGutters }) => {
  return (
    <div className={className}>
      <div className="container">
        <div className={!noGutters ? "row" : "row no-gutters"}>
          <div className="col-12">
            <SectionTitle title={title} subTitle={subTitle} />
          </div>
          {slider ? (
            <div
              className="col-lg-10 col-12"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Slider className="teamSlideArea" {...settings}>
                {teams.map(team => (
                  <TeamWrapper team={team} key={`slider_${team.name}`} />
                ))}
              </Slider>
            </div>
          ) : (
            <Fragment>
              {teams.map((team, i) => (
                <div key={i} className="col-lg-4 col-md-6 col-12">
                  <TeamWrapper team={team} key={`frag_${team.name}`} />
                </div>
              ))}
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
export default TeamMember;
