import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import "./style.scss";

const areasDeAtuacao = [
  { level: "Cível", value: "Cível" },
  { level: "Trabalhista e Previdenciário", value: "Trabalhista e Previdenciário" },
  { level: "Tributário", value: "Tributário" },
  { level: "Administrativo", value: "Administrativo" },
  { level: "Compliance", value: "Compliance" },
  { level: "Outro", value: "Outro" },
];

class Form extends Component {
  state = {
    nome: "",
    telefone: "",
    email: "",
    area_atuacao: areasDeAtuacao[0].value,
    mensagem: "",
    error: {},
  };

  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case "string.email":
              err.message = "DIGITE UM EMAIL VÁLIDO";
              break;
            default:
              err.message = "O EMAIL NÃO PODE ESTAR VAZIO";
              break;
          }
        });
        return errors;
      }),
    telefone: Joi.string()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            default:
              err.message = "O TELEFONE NÃO PODE ESTAR VAZIO";
              break;
          }
        });
        return errors;
      }),
    nome: Joi.string()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            default:
              err.message = "O NOME NÃO PODE ESTAR VAZIO";
              break;
          }
        });
        return errors;
      }),
    mensagem: Joi.string()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            default:
              err.message = "A DESCRIÇÃO NÃO PODE ESTAR VAZIA";
              break;
          }
        });
        return errors;
      }),
    area_atuacao: Joi.string(),
  };

  changeHandler = event => {
    const error = { ...this.state.error };
    const errorMessage = this.validationProperty(event);

    if (errorMessage) {
      error[event.target.name] = errorMessage;
    } else {
      delete error[event.target.name];
    }

    this.setState({
      [event.target.name]: event.target.value,
      error,
    });
  };

  handleChange = value => {
    this.setState({
      country: value,
    });
  };

  validationProperty = event => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);

    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };

    const form = {
      nome: this.state.nome,
      email: this.state.email,
      telefone: this.state.telefone,
      mensagem: this.state.mensagem,
    };

    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  submitHandler = event => {
    event.preventDefault();
    const error = this.validate();

    if (error) {
      this.setState({
        error: error || {},
      });
    } else {
      const request = new XMLHttpRequest();
      request.onload = () => {
        const jsonResponse = JSON.parse(request.responseText);
        if (200 === request.status && "OK" === jsonResponse.msg) {
          this.setState({
            nome: "",
            telefone: "",
            email: "",
            area_atuacao: "",
            mensagem: "",
          });

          toast.success("Recebemos sua mensagem. Aguarde nosso contato.", {
            autoClose: 15000,
            pauseOnHover: true,
            closeOnClick: true,
          });

          return;
        }

        toast.warning(jsonResponse.msg + ". Verifique os campos e tente novamente.", {
          autoClose: 15000,
          pauseOnHover: true,
          closeOnClick: true,
        });
      };

      request.overrideMimeType("application/json");
      request.open("POST", "/contato.php", true);
      request.send(
        JSON.stringify({
          nome: this.state.nome,
          telefone: this.state.telefone,
          email: this.state.email,
          area_atuacao: this.state.area_atuacao,
          mensagem: this.state.mensagem,
        })
      );
    }
  };

  render() {
    return (
      <form onSubmit={this.submitHandler} className="contactForm">
        <div className="row">
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input placeholder="Seu nome" value={this.state.nome} name="nome" onChange={this.changeHandler} className="form-control" type="text" />
              {this.state.error.nome && <p>{this.state.error.nome}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input
                placeholder="Telefone"
                value={this.state.telefone}
                name="telefone"
                onChange={this.changeHandler}
                className="form-control"
                type="telefone"
              />
              {this.state.error.telefone && <p>{this.state.error.telefone}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <input placeholder="Email" value={this.state.email} name="email" onChange={this.changeHandler} className="form-control" type="email" />
              {this.state.error.email && <p>{this.state.error.email}</p>}
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div className="formInput">
              <select value={this.state.area_atuacao} className="form-control" onChange={this.changeHandler} name="area_atuacao">
                {areasDeAtuacao.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.level}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-12">
            <div className="formInput">
              <textarea className="form-control" value={this.state.mensagem} onChange={this.changeHandler} placeholder="Descreva seu caso..." name="mensagem" />
              {this.state.error.mensagem && <p>{this.state.error.mensagem}</p>}
            </div>
          </div>
          <div className="col-12">
            <button type="submit">Enviar</button>
          </div>
        </div>
      </form>
    );
  }
}

export default Form;
