import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import FooterArea from "../../../components/FooterArea";
import breadcumb from "../../../images/breadcumb/bg.jpg";
import single from "../../../images/areas-de-atuacao/tributario770x500.png";
// import "../PaginaAreasDeAtuacao/style.scss";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Áreas de atuação", route: "/areas-de-atuacao" }, { name: "Tributário" }];

const SinglePortfolioPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Tributário" breadcumbMenu={breadcumbMenu} background={breadcumb} />

      <div className="aboutArea aboutAreaStyleTwo">
        <div className="container">
          <div className="row">
            <div className="order-last col col-lg-6">
              <div className="aboutImageWrap">
                <img src={single} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutContent">
                <span>Atuação</span>
                <h2>Tributário</h2>
                <p>
                    Exame do caso concreto e a aplicação da legislação competente, visando dirimir as dúvidas quanto aos procedimentos fiscais e tributários,
                    através de respostas formais encaminhadas ao cliente.
                  </p>
                  <p>
                    Revisão da apuração de todas as espécies de tributo com o objetivo de identificar pontos em descompasso com a legislação vigente ou com
                    decisões emanadas pela autoridade fiscal.
                  </p>
                  <p>Formulação de consulta a órgão competente em busca de amparo legal com cunho administrativo.</p>
                  <p>
                    Avaliação do regime de tributação da empresa e respectivas operações fiscais em conjunto com os aspectos societários e contábeis,
                    objetivando a gestão legal da carga tributária futura.
                  </p>
                  <p>
                    Análise do Auto de Infração e consequente defesa administrativa e judicial, nos limites da Constituição Federal e da Lei, visando a sua
                    anulação ou redução.
                  </p>
                  <p>
                    Propositura de medida judicial para a obtenção de CND (Certidão Negativa de Débito), suspensão de exigibilidade de tributos, depósito
                    judicial de valores referentes a tributos sob questionamento administrativo ou judicial, ou, ainda, para promover a fluência do devido
                    processo legal para que seja possível exercer o direito de defesa do contribuinte.
                  </p>
                  <p>
                    Recuperação de valores pagos de forma indevida ou a maior a título de tributo, bem como a suspensão de recolhimentos vincendos quando a
                    exigência tributária vigente confrontar a Constituição e a Legislação em vigor.
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterArea />
    </Fragment>
  );
};

export default SinglePortfolioPage;
