import React from 'react'
import Form from '../Form'
import './style.scss'
const ContactArea = ({ className }) => {
    return (
        <div id="podemos-te-ajudar" className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <div className="contactInfo">
                            <span>Aos nossos honrados clientes</span>
                            <h2>Podemos te ajudar?</h2>
                            <h6 style={{lineHeight: 1.5}}>
                                Preencha brevemente o formulário ou nos faça uma ligação<br />
                                61 3042 0123
                            </h6>
                            <p>Nossa equipe lhe retornará o mais breve possível</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7">
                        <Form />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactArea
