import React, { Fragment } from "react";
import HeaderBottom from "../../../components/HeaderBottom";
import HeaderTop from "../../../components/HeaderTop";
import Breadcumb from "../../../components/Breadcumb";
import Service from "../../../components/Service";

import TeamMember from "../../../components/TeamMember";
import FooterArea from "../../../components/FooterArea";

import about from "../../../images/about/4.jpg";
import breadcumb from "../../../images/breadcumb/bg.jpg";

const breadcumbMenu = [{ name: "Home", route: "/" }, { name: "Sobre nós" }];

const AboutPage = () => {
  return (
    <Fragment>
      <header className="headerArea">
        <HeaderTop className="headerTop" />
        <HeaderBottom className="headerBottomArea" />
      </header>
      <Breadcumb className="breadcumbArea" title="Sobre nós" breadcumbMenu={breadcumbMenu} background={breadcumb} />
      <Service className="serviceArea mt-0" />

      <div className="aboutArea aboutAreaStyleTwo" style={{marginBottom: '0', paddingBottom: '0'}}>
        <div className="container">
          <div className="row">
            <div className="order-last col col-lg-6">
              <div className="aboutImageWrap">
                <img src={about} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="aboutContent">
                <span>O Escritório</span>
                <h2>Apresentação</h2>
                <p>O escritório Araújo Rabelo atua, no Distrito Federal e Entorno, há mais de 13 anos na área jurídica preventiva e contenciosa das empresas, prestando uma assessoria completa, atenta às necessidades efetivas do mercado corporativo, fazendo com que, por meio de uma abordagem interdisciplinar, os ditames regulatórios sejam cumpridos na execução do seu negócio.</p>

                <h2>Cenário econômico</h2>
                <p>As empresas brasileiras estão convivendo com vários desafios que as expõem ao risco da descontinuidade, especialmente, no que tange aos aspectos jurídicos do negócio.</p>
                <p>O ímpeto de um empreendimento sem o suporte de um plano de negócios com a avaliação prévia dos reflexos jurídicos que circundam a atividade empresarial pode comprometer a curto, médio, ou longo prazo uma ideia promissora ou a respectiva estratégia negocial com possibilidade de êxito.</p>

                <h2>Gestão Jurídica Empresarial</h2>
                <p>A Araújo Rabelo é um modelo de trabalho orientado por uma política de valores capaz de alocar e gerir recursos, ações, iniciativas, princípios, valores e estratégias, viabilizando o alcance dos objetivos propostos pela empresa.</p>
                <p>O mercado necessita de uma gestão jurídica que entenda a realidade do setor econômico e proponha com sua expertise alternativa para a tomada de decisões pela empresa.</p>
                <p>Nossos profissionais atuam de forma integrada, para a entrega de um serviço personalizado, conduzindo as decisões necessárias para levar adiante o desenvolvimento da atividade empresarial e no intuito de obter sempre o melhor resultado.</p>
                <p>Reduzir custos, atualmente, é muito mais do que cortar números, é saber identificar as deficiências do próprio negócio e a contingenciar riscos.</p>
                <p>Nesta sequência, a advocacia enquanto gestora jurídica deve ser aliada das empresas na sua função de gerir e agregar valores e riquezas.</p>

                <h2>“Somos uma Gestão Jurídica de Investimento — e não de custo — imprescindível para sua empresa, aumentando suas vantagens competitivas.”</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <TeamMember title="Advogados qualificados " subTitle="Conheça nossos profissionais" className="teamArea" />
      <FooterArea />
    </Fragment>
  );
};
export default AboutPage;
