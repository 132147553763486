import React from "react";
import "./style.scss";

const HeaderTop = props => {
  return (
    <div className={props.className}>
      <div className="container">
        <div className="headerTopMainWrapper">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-5 col-12">
              <ul className="d-flex accountLoginArea">
                <li>
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  QS 02, Conj. 6, Lote 22, Riacho Fundo I, Brasília-DF
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-5 col-12">
              <ul className="headerContact">
                <li>
                  <i className="fa fa-phone"></i>
                  (61) 3042-0123
                </li>
                <li>
                  <i className="fa fa-clock-o"></i>
                  9h às 18h
                </li>
              </ul>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-2 col-12">
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <div className="btnStyle btnStyle2">
                    <a href="https://www.waze.com/ul?ll=-15.88272980%2C-48.01130120&navigate=yes">Waze</a>
                  </div>
                  <div className="btnStyle btnStyle2">
                    <a href="https://wa.me/5561999726254?text=Ol%C3%A1.">WhatsApp</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTop;
